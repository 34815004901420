<template>
  <div class="footer--find">
    <div class="footer--title mb-3">
      {{ $t('footer.find.header') }}
    </div>
    <div class="d-flex flex-wrap mb-3">
      <!--      <router-link to="/cari/rumah-subsidi" class="footer&#45;&#45;nav mb-2">-->
      <!--        {{ $t('footer.find.subsidizedHouse') }}-->
      <!--      </router-link>-->
      <router-link to="/direktori/rumah" class="footer--nav mb-2">
        {{ `${$t('footer.find.house')} ${$t('general.sold')}` }}
      </router-link>
      <router-link to="/direktori/apartemen" class="footer--nav mb-2">
        {{ `${$t('footer.find.apartment')} ${$t('general.sold')}` }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
