<template>
  <footer class="footer--container">
    <footer-directory></footer-directory>
    <div class="container d-flex flex-wrap">
      <div class="column--1">
        <div class="footer--logo">
          <router-link to="/">
            <img :src="logoUrl" alt="BeliRumah" />
          </router-link>
        </div>
        <div class="footer--social">
          <a href="https://instagram.com/belirumahco" target="_blank">
            <div class="btn btn-primary mr-4">
              <img src="@/assets/img/icons/instagram.svg" />
            </div>
          </a>
          <a href="https://www.tiktok.com/@belirumah.co" target="_blank">
            <div class="btn btn-primary mr-4">
              <img src="@/assets/img/icons/tiktok.svg" />
            </div>
          </a>
          <a href="https://www.threads.net/@belirumahco" target="_blank">
            <div class="btn btn-primary">
              <img src="@/assets/img/icons/threads.png" />
            </div>
          </a>
        </div>
        <!--        <div class="footer&#45;&#45;about"></div>-->
        <!--        <div class="footer&#45;&#45;language">-->
        <!--          <v-select-->
        <!--            :value="locale"-->
        <!--            @input="setLang"-->
        <!--            :items="locales"-->
        <!--            item-value="id"-->
        <!--            item-text="name"-->
        <!--            :label="$t('general.language')"-->
        <!--            class="basic&#45;&#45;dropdown"-->
        <!--            solo-->
        <!--            hide-details-->
        <!--          ></v-select>-->
        <!--        </div>-->
        <div class="footer--copyright mb-4">
          Prosperity Tower 8th Floor<br />
          District 8, SCBD Lot 28<br />
          JI. Jend. Sudirman Kav. 52-53<br />
          Jakarta Selatan 12190<br />
          Telp.: 021-38959193
        </div>
        <hr />
        <!--        <div class="footer&#45;&#45;copyright mb-4">-->
        <!--          PT REAL ESTATE TEKNOLOGI<br />-->
        <!--          Rukan Cordoba BLOK H No. 75, JL. Raya Marina Indah, Pantai Indah<br />-->
        <!--          Kapuk, Desa/Kelurahan Kamal Muara, Kec. Penjaringan, Kota Adm.<br />-->
        <!--          Jakarta Utara, Provinsi DKI Jakarta,<br />-->
        <!--          Kode Pos: 14470<br />-->
        <!--          02129516335-->
        <!--        </div>-->
        <div class="footer--copyright">
          {{ $t('footer.copyright1') }}
          {{ new Date().getFullYear() }}
          {{ $t('footer.copyright2') }}
        </div>
      </div>
      <div class="column--2">
        <div class="footer--title mb-3">
          {{ $t('footer.company.header') }}
        </div>
        <div class="d-flex flex-wrap mb-3">
          <!--          <router-link to="/news" class="footer&#45;&#45;nav col-4 pa-0 mb-2">-->
          <!--            {{ $t('footer.company.news') }}-->
          <!--          </router-link>-->
          <!--          <router-link to="/help" class="footer&#45;&#45;nav col-6 pa-0 mb-2">-->
          <!--            {{ $t('footer.company.helpCenter') }}-->
          <!--          </router-link>-->
          <router-link to="/syarat-ketentuan" class="footer--nav col-6 pa-0 mb-2">
            {{ $t('footer.company.tos') }}
          </router-link>
          <router-link to="/kebijakan-privasi" class="footer--nav col-6 pa-0 mb-2">
            {{ $t('footer.company.privacy') }}
          </router-link>
          <a
            href="https://komunitas.belirumah.co"
            target="_blank"
            class="footer--nav col-6 pa-0 mb-2"
          >
            {{ $t('footer.company.forum') }}
          </a>
          <a
            href="https://blog-belirumah.reteknologi.com"
            target="_blank"
            class="footer--nav col-6 pa-0 mb-2"
          >
            {{ $t('footer.company.blog') }}
          </a>
          <a class="footer--nav col-6 pa-0 mb-2" @click="showConsultation">
            {{ $t('home.consultation.button') }}
          </a>
          <a href="/mitra-proyek" class="footer--nav col-6 pa-0 mb-2">
            {{ $t('general.developerPartner') }}
          </a>
          <a
            :href="`mailto:${$t('footer.company.contactEmail')}`"
            class="footer--nav col-6 pa-0 mb-2"
          >
            {{ $t('footer.company.contact') }}
          </a>
          <a href="/lms" class="footer--nav col-6 pa-0 mb-2">
            {{ $t('packages.shortTitle') }}
          </a>
          <a href="https://rentfix.com" target="_blank" class="footer--nav col-6 pa-0 mb-2">
            Rentfix.com
          </a>
        </div>
        <div class="footer--column-wrapper">
          <div class="footer--country-mobile">
            <!--            <div class="footer--country">-->
            <!--              <div class="footer--title mb-3">-->
            <!--                {{ $t('footer.country.header') }}-->
            <!--              </div>-->
            <!--              <a href="https://belirumah.co" target="_blank" class="footer--nav mb-3">-->
            <!--                {{ $t('footer.country.id') }}-->
            <!--              </a>-->
            <!--            </div>-->
            <div class="footer--mobile mt-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.beliruma.app"
                target="_blank"
              >
                <img src="@/assets/img/home/google-play-badge.svg" alt="Google Play Store" />
              </a>
              <a href="https://apps.apple.com/us/app/beliruma/id1625061665" target="_blank">
                <img src="@/assets/img/home/badges-app-store.svg" alt="Apple App Store" />
              </a>
            </div>
          </div>
          <footer-find v-show="$mq === 'xs'"></footer-find>
        </div>
      </div>
      <div class="column--3">
        <footer-find v-show="$mq !== 'xs'"></footer-find>
        <footer-mortgage></footer-mortgage>
        <!--        <div class="footer--title mb-3">-->
        <!--          {{ $t('footer.payment') }}-->
        <!--        </div>-->
        <!--        <div class="footer--payment-partner">-->
        <!--          <img src="@/assets/img/bank/alto.png" />-->
        <!--          <img src="@/assets/img/bank/visa.svg" />-->
        <!--          <img src="@/assets/img/bank/master.svg" />-->
        <!--          <img src="@/assets/img/bank/atm-bersama.png" />-->
        <!--          <img src="@/assets/img/bank/midtrans.png" />-->
        <!--          <img src="@/assets/img/bank/permata.svg" />-->
        <!--          <img src="@/assets/img/bank/bni.svg" />-->
        <!--        </div>-->
      </div>
    </div>
  </footer>
</template>

<script>
import FooterDirectory from '@/components/layouts/partials/footer-directory';
import FooterFind from '@/components/layouts/partials/footer-find';
import FooterMortgage from '@/components/layouts/partials/footer-mortgage';
import { mapState } from 'vuex';
export default {
  components: {
    FooterDirectory,
    FooterFind,
    FooterMortgage,
  },
  computed: {
    ...mapState({
      logoUrl: (state) => state.global.logoUrl,
      locales: (state) => state.global.locales,
      locale: (state) => state.global.locale,
    }),
  },
  methods: {
    setLang(locale) {
      this.$store.commit('global/SET_LANG', locale);
      this.$router.go(0);
    },
    showConsultation() {
      this.$modal.show('modal--consultation');
    },
  },
};
</script>
